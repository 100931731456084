import Vue from 'vue'
import VueRouter from 'vue-router'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/share/app',
  },
  {
    path: '/shareVideoDrama',
    name: 'shareVideoDrama',
    meta: {
      title: '祝语APP',
    },
    component: resolve => require(['@/views/shareVideoDrama.vue'], resolve),
  },
  {
    path: '/helIndex',
    name: 'helIndex',
    meta: {
      title: 'helIndex',
    },
    component: resolve => require(['@/views/helIndex.vue'], resolve),
  },
  {
    path: '/getSharedInfo',
    name: 'getSharedInfo',
    meta: {
      title: '学习资料',
    },
    component: resolve => require(['@/views/studentInfo.vue'], resolve),
  },
]

const router = new VueRouter({
  mode: 'history',
  routes,
})
router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next()
})
router.afterEach(() => {
  window.scrollTo(0, 0)
})

export default router
